<template>
    <div class="client-page">
        <div class="client-title-a">团队客户</div>
        <div>
            <div class="searchOpen">
                <search
                    style="margin-top:10px"
                    :team-customer="true"
                    :is-customer="true"
                    :label-arr="labelArr"
                    @clickData="clickData"
                />
            </div>
            <el-card class="main" :style="{'margin-top': '10px'}">
                <!-- 搜索区域 -->
                <div class="search flex-sb">
                    <div class="flex">
                        <el-button
                            v-if="showRegiveBtn"
                            :class="client_Ids.length > 0 ? 'regive' : 'regive-normal'"
                            size="mini"
                            style="margin-right:10px"
                            :disabled="client_Ids.length == 0"
                            @click="distribution(0)"
                        >重分配</el-button>
                        <div class="top-search">
                            <el-input
                                v-model="status.keyword"
                                clearable
                                size="small"
                                placeholder="请输入姓名/手机号"
                                @keypress.native.enter="getTableData()"
                                @clear="getTableData()"
                            />
                            <!-- <i class="el-icon-search"></i> -->
                            <div class="icon-area" @click="getInputData()">
                                <img width="20" height="20" src="@/assets/common/search.png" alt />
                            </div>
                        </div>
                    </div>

                    <div class="flex right-time">
                        <div>最后跟进时间</div>
                        <div>
                            <el-radio-group v-model="timeTabs" size="small" @change="changeTime">
                                <el-radio-button label="0">全部</el-radio-button>
                                <el-radio-button label="1">今日</el-radio-button>
                                <el-radio-button label="2">昨日</el-radio-button>
                                <el-radio-button label="3">最近一周</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div>
                            <el-date-picker
                                v-model="valuetime"
                                style="width:350px"
                                size="mini"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="timestamp"
                                :default-time="['00:00:00', '23:59:59']"
                                @change="time"
                            >style="width:250px"</el-date-picker>
                        </div>
                        <img
                            width="30"
                            height="30"
                            src="@/assets/customer/showHeadIcon.svg"
                            alt
                            @click="showOrHidden"
                        />
                    </div>
                </div>
                <!-- 提示弹框 -->
                <trace-info ref="traceInfo" />
                <!-- 表格区域 -->
                <el-table
                    ref="multipleTable"
                    v-loading="loading"
                    :data="tableData"
                    border
                    style="width: 100%;margin-top:10px"
                    :height="tableHeight(332)"
                    @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" fixed />
                    <el-table-column fixed label="序号" type="index" width="55" />
                    <el-table-column fixed label="重要" width="55">
                        <template slot-scope="scope">
                            <i
                                class="iconfont icon-aixin xin"
                                :class="{ ping: scope.row.important == 1 }"
                            />
                        </template>
                    </el-table-column>
                    <span v-for="(item, index) in colData" :key="index">
                        <el-table-column
                            v-if="item.selected == 1"
                            :key="index"
                            :label="item.columnName"
                            :prop="item.propertyName"
                            :min-width="item.propertyName == 'quality' || item.propertyName == 'qualityLevel' ? '190px' : item.propertyName == 'category' || item.propertyName == 'age'? '100px' : item.propertyName == 'followLogList' ? '500px' : item.width"
                        >
                            <template slot-scope="scope">
                                <span v-if="item.propertyName == 'quality'">
                                    <span
                                        v-if="scope.row.hasHouse == 1"
                                        class="icon house"
                                        style="margin-right: 10px"
                                    >房</span>
                                    <span
                                        v-if="scope.row.hasVehicle == 1"
                                        class="icon car"
                                        style="margin-right: 10px"
                                    >车</span>
                                    <span
                                        v-if="scope.row.hasShebao == 1"
                                        style="margin-right: 10px"
                                        class="icon shebao"
                                    >社</span>
                                    <span
                                        v-if="scope.row.hasGongjijin == 1"
                                        class="icon gongjijin"
                                        style="margin-right: 10px"
                                    >金</span>
                                    <span
                                        v-if="scope.row.hasBaodan == 1"
                                        style="margin-right: 10px"
                                        class="icon baodan"
                                    >保</span>
                                </span>
                                <span v-else-if="item.propertyName == 'category'">
                                    <img
                                        v-if="scope.row.category == 1"
                                        width="55"
                                        src="@/assets/customer/companySea.png"
                                        alt
                                    />
                                    <img
                                        v-if="scope.row.category == 0"
                                        width="55"
                                        src="@/assets/customer/departmentSea.png"
                                        alt
                                    />
                                </span>
                                <span v-else-if="item.propertyName == 'followLogList'">
                                    <span slot="reference" @click="showDetail(scope.row)">
                                        <span
                                            v-if="scope.row.followLogList.length > 0"
                                            class="show-content"
                                        >{{ scope.row.followLogList[0].modifyContent }}</span>
                                        <span
                                            v-if="scope.row.followLogList[0].modifyContent == ''"
                                        >点击查看跟进信息</span>
                                    </span>
                                </span>
                                <el-rate
                                    v-else-if="item.propertyName == 'qualityLevel'"
                                    v-model="scope.row.qualityLevel"
                                    disabled
                                    text-color="#ff9900"
                                />
                                <span
                                    v-else-if="item.propertyName == 'fundNeeds'"
                                >{{ scope.row.fundNeeds || scope.row.fundNeeds == 0 ? scope.row.fundNeeds + "万" : "" }}</span>
                                <span
                                    v-else-if="item.propertyName == 'age'"
                                >{{ scope.row.age || scope.row.age === 0 ? scope.row.age + "岁" : "未知" }}</span>
                                <span v-else>{{ scope.row[scope.column.property] }}</span>
                            </template>
                        </el-table-column>
                    </span>
                    <el-table-column fixed="right" label="操作" min-width="160">
                        <template slot-scope="scope">
                            <el-button
                                type="primary"
                                size="mini"
                                @click="customerDetail(scope.row)"
                            >查看</el-button>
                            <el-button
                                v-if="showRegiveBtn"
                                style="margin-left:10px"
                                class="regive"
                                size="mini"
                                @click="distribution(1, scope.row)"
                            >重分配</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="block" style="margin-top: 10px; text-align: right">
                    <el-pagination
                        :current-page="page"
                        :page-size="size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="len"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
                <assign-dialog
                    :show-dialog.sync="showDialog"
                    :title="title"
                    @submitPartment="submitPartment"
                />
                <show-or-hidden
                    ref="showOrHidden"
                    :show-dialog.sync="showCol"
                    @submitShowOrHidden="submitShowOrHidden"
                />
                <customer-info ref="customerInfo" />
            </el-card>
        </div>
    </div>
</template>

<script>
import { clients, exchange } from '@/api/team_client.js';
import { columnShow, showOrHiddenCol, elements } from '@/api/public_content';
import { getYMDHMS } from '@/assets/js/time.js';
import elementResizeDetectorMaker from 'element-resize-detector';
import resize from '@/mixins/resize';
export default {
    mixins: [resize],
    components: {
        search: () => import('@/components/templates/search-open'),
        traceInfo: () => import('@/components/templates/trace-info'),
        assignDialog: () => import('./components/assignDialog'),
        showOrHidden: () => import('./components/showOrHidden'),
        customerInfo: () => import('./components/customerInfo'),
    },
    data() {
        return {
            labelArr: [
                '客户资质',
                '资金需求',
                '跟进阶段',
                '获得方式',
                '意向度',
                '资质星级',
            ],
            status: {
                keyword: '', //input搜索关键词
                fundNeedsScopeType: '', //资金需求
                departmentId: '', //归属部门ID
                qualityType: '', //资质类型
                followStage: '',
                getCategory: '', //获得方式
                startTime: '', //开始时间
                endTime: '', //结束时间
                orderBy: '', //排序方式
                qualityLevel: '', //星级
                purposeLevel: '', //资质等级
                pageNo: 1, //页码数
                pageSize: 10, //数据量
            },
            page: 1,
            len: 0,
            size: 10,
            loading: true,
            valuetime: '', //选择转为时间值
            timeTabs: 0, // 时间选中
            // tableHeight: window.innerHeight - 310,
            tableData: [], //数据列表
            colData: [],
            client_Ids: [], //表格选中的CheckBox存放客户id值
            showDialog: false, //展示选择部门
            showCol: false, //
            type: '',
            singleClientId: [],
            title: '选择要重分配的对象',
            showRegiveBtn: false,
            regiveType: '',
            searchOpenHeight: '',
            onRiseHeight: window.innerHeight - 330,
        };
    },
    watch: {
        /**
         * 监控表格的数据data，自动设置表格宽度
         * @param valArr
         */
        // 控制表格长度
        tableData(valArr) {
            console.log('valArr', valArr);
            const _this = this;
            this.colData = this.colData.map((value) => {
                const arr = valArr.map((x) => {
                    // console.log(x);
                    if (value.propertyName == 'followLogList') {
                        return x[value.propertyName][
                            x[value.propertyName].length - 1
                        ].modifyContent;
                    } else {
                        return x[value.propertyName];
                    }
                }); // 获取每一列的所有数据
                arr.push(value.columnName); // 把每列的表头也加进去算
                value.width = _this.getMaxLength(arr) + 20; // 每列内容最大的宽度 + 表格的内间距(依据实际情况而定)
                return value;
            });
        },
    },
    created() {
        // window.addEventListener("resize", this.getHeight);
        // window.addEventListener('resize', this.watchSize);
        this.getColData();
        this.getShowRegive();
    },
    mounted() {
        // this.watchSize();
        this.getTableData();
    },
    destroyed() {
        // window.removeEventListener('resize', this.getHeight);
        // window.addEventListener('resize', this.watchSize);
    },
    methods: {
        // watchSize() {
        //     const _this = this;
        //     const erd = elementResizeDetectorMaker({
        //         strategy: 'scroll',
        //     });
        //     erd.listenTo(
        //         document.getElementsByClassName('searchOpen'),
        //         (element) => {
        //             let height = element.offsetHeight;
        //             _this.$nextTick(() => {
        //                 _this.searchOpenHeight = height - 50;
        //                 //监听到事件后执行的业务逻辑
        //             });
        //         }
        //     );
        // },
        // 重分配
        getShowRegive() {
            elements({
                pageCode: 'team-client',
            }).then((res) => {
                console.log(res);
                for (let i = 0; i < res.data.list.length; i++) {
                    if (res.data.list[i].name === '重分配按钮') {
                        this.showRegiveBtn = true;
                    }
                }
            });
        },
        // getHeight() {
        //     // this.tableHeight = this.tableData.length > 0 ? window.innerHeight - 250 : "";
        //     this.tableHeight = window.innerHeight - 310;
        // },
        // 根据内容控制表格长度
        getMaxLength(arr) {
            return arr.reduce((acc, item) => {
                if (item) {
                    let calcLen = this.getTextWidth(item);
                    if (acc < calcLen) {
                        acc = calcLen;
                    }
                }
                return acc;
            }, 0);
        },
        // 根据内容控制表格长度
        getTextWidth(str) {
            let width = 0;
            let html = document.createElement('span');
            html.innerText = str;
            html.className = 'getTextWidth';
            document.querySelector('body').appendChild(html);
            width = document.querySelector('.getTextWidth').offsetWidth;
            document.querySelector('.getTextWidth').remove();
            return width;
        },
        handleSelectionChange(val) {
            this.client_Ids = val;
        },
        // 获取表头数据
        getColData() {
            // 获取列显隐字段
            let queryCol = {
                tableName: 'teamClientTable',
                defaultValue: 0,
            };
            columnShow(queryCol).then((res) => {
                if (res.code == 0) {
                    this.colData = res.data.list;
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        // 点击搜索获取数据
        getInputData() {
            this.status.pageNo = 1;
            // console.log(this.status.pageNo,'this.status.pageNo')
            this.page = 1;
            this.getTableData();
        },

        // 获取数据
        getTableData() {
            // this.$nextTick(() => {
            //   this.$refs.multipleTable.doLayout();
            // });
            // 获取列表数据
            clients(this.status).then((res) => {
                // this.loading = true
                if (res.code == 0) {
                    this.tableData = res.data.list;
                    this.len = res.data.total;
                    this.tableData.forEach((item) => {
                        item.createdTime = getYMDHMS(item.createdTime);
                        item.lastFollowedTime = getYMDHMS(
                            item.lastFollowedTime
                        );
                        item.lastTransferredTime = getYMDHMS(
                            item.lastTransferredTime
                        );
                        // 跟进阶段
                        let followStage = this.common.allValueNeed(
                            'client-follow-stage'
                        );
                        followStage.forEach((i) => {
                            if (item.followStage == i.number) {
                                item.followStage = i.cnVal;
                            }
                        });
                        // 性别
                        let clientSex = this.common.allValueNeed('sex');
                        clientSex.forEach((i) => {
                            if (item.sex == i.number) {
                                item.sex = i.cnVal;
                            }
                        });
                        // 获得方式
                        let clientGet = this.common.allValueNeed(
                            'client-get-category'
                        );
                        clientGet.forEach((every) => {
                            if (item.getCategory == every.number) {
                                item.getCategory = every.cnVal;
                            }
                        });
                        // 意向度
                        let purposeLevel =
                            this.common.allValueNeed('loan-purpose-level');
                        purposeLevel.forEach((x) => {
                            if (item.purposeLevel == x.number) {
                                item.purposeLevel = x.cnVal;
                            }
                        });
                    });

                    //  解决 element ui table 错位 bug
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout();
                    });

                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 点击后拿到的数据
        clickData(val) {
            console.log('val', val);
            // purposeLevel 意向度
            // qualityType 客户资质
            // fundNeedsScopeType 资质需求
            // followStage 跟进阶段
            // qualityLevel 资质星级
            this.status.purposeLevel = val.purposeLevel;
            this.status.qualityType = val.qualityType;
            this.status.fundNeedsScopeType = val.fundNeedsScopeType;
            this.status.qualityLevel = val.qualityLevel;
            this.status.followStage = val.followStage;
            this.status.departmentId = val.departSeaVal;
            this.status.orderBy = val.customerOrderBy;
            this.status.getCategory = val.getCategory;
            this.status.departmentId = val.departmentId;
            this.status.staffId = val.staffId;
            this.getTableData();
        },
        // 选择时间筛选
        changeTime() {
            console.log(this.timeTabs);
            const start = new Date(new Date(new Date().toLocaleDateString()));
            const end = new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
            );
            // 获取昨天
            let zuo = start.getTime() - 3600 * 1000 * 24 * 1;
            // 获取周
            let zhou = start.getTime() - 3600 * 1000 * 24 * 6;
            if (this.timeTabs == 0) this.valuetime = []; // 全部
            if (this.timeTabs == 1) this.valuetime = [start.getTime(), end]; //今日
            if (this.timeTabs == 2) this.valuetime = [zuo, start.getTime() - 1]; // 昨日
            if (this.timeTabs == 3) this.valuetime = [zhou, end]; //最近一周
            console.log('this.valuetime', this.valuetime);
            this.status.startTime = this.valuetime[0] / 1000;
            this.status.endTime = this.valuetime[1] / 1000;
            this.status.pageNo = 1;
            this.page = 1;
            this.getTableData();
        },
        // 时间选择
        time(val) {
            console.log(val);
            if (val == null) {
                this.timeTabs = 0;
                this.valuetime = '';

                // this.handleCurrentChange(1);
            } else {
                this.timeTabs = '';
                this.valuetime = val;

                // this.handleCurrentChange(1);
            }
            this.status.startTime = this.valuetime[0] / 1000;
            this.status.endTime = this.valuetime[1] / 1000;
            this.handleCurrentChange(1);
        },
        //分页
        handleSizeChange(val) {
            this.size = val;
            this.status.pageSize = this.size;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.status.pageNo = this.page;
            this.getTableData();
        },
        showOrHidden() {
            console.log('出现列显隐');
            this.showCol = true;
            this.$refs.showOrHidden.init(this.colData);
        },
        showDetail(info) {
            console.log(info);
            // console.log(this.$refs.traceInfo);
            this.$refs.traceInfo.showDetail(info);
        },
        // 重分配
        distribution(type, row) {
            this.showDialog = true;
            this.type = type;
            if (type == 1) {
                this.client_Ids = [];
                this.$refs.multipleTable.clearSelection();
                this.singleClientId = [row];
            }
            // this.$refs.multipleTable.doLayout()
        },
        // 重分配选完弹框后点击提交操作
        submitPartment(val) {
            console.log(val);
            let newClientIds;
            if (this.type == 1) {
                newClientIds = this.singleClientId.map((x) => x.id);
            } else {
                newClientIds = this.client_Ids.map((x) => x.id);
            }
            debugger;
            exchange({
                clientIds: newClientIds,
                staffId: val,
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '已成功分配到此销售',
                        type: 'success',
                    });
                    this.showDialog = false;
                    this.client_Ids = [];
                    this.$refs.multipleTable.clearSelection();
                    this.singleClientId = [];
                    this.getTableData();
                } else {
                    this.$message.error('参数不可为空');
                }
            });
        },
        // 列显隐提交按钮方法
        submitShowOrHidden(val) {
            console.log(val);
            let data = [];
            // 传回来的是要隐藏的值 这里做的是把隐藏的筛选掉传回后端是显示的值
            let newArr = this.colData.filter(
                (item) => !val.some((ele) => item.columnName == ele)
            );
            newArr.forEach((item) => {
                data.push(item.columnName);
            });
            showOrHiddenCol({
                tableName: 'teamClientTable',
                selectedColumns: data,
            }).then((res) => {
                if (res.code == 0) {
                    this.showCol = false;
                    this.getTableData();
                    this.getColData();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 查看按钮
        customerDetail(row) {
            this.$refs.customerInfo.init(row.id, false);
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/table.less';

.flex {
    display: flex;
    align-items: center;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #333;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.client-page {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;

    // overflow: scroll;
    .client-title-a {
        text-align: center;
        color: #7f7f7f;
        font-size: 16px;
        margin: 12px 0 11px;
    }

    .main {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.search {
    .top-search {
        position: relative;
        min-width: 160px;
        width: 55%;

        /deep/ .el-input__clear {
            margin-right: 23px;
        }

        .icon-area {
            position: absolute;
            right: 5px;
            top: 5px;
            border-left: 1px solid #ccc;
            padding-left: 5px;
            cursor: pointer;
        }
    }

    .regive {
        background-color: rgb(252, 178, 44);
        color: #fff;

        &:hover {
            background-color: rgb(253, 201, 108);
        }

        &:active {
            background-color: rgb(222, 153, 20);
            border-color: #fff;
        }
    }

    .right-time {
        div:not(:last-child) {
            margin-right: 10px;
        }

        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #4086ec;
            background-color: #ecf3fd;
            box-shadow: 0 0px 0px #ccc;
            border-color: #dcdfe6;
        }

        /deep/ .el-radio-button__inner {
            color: #aaa;
        }
    }
}

.regive {
    background-color: rgb(252, 178, 44);
    color: #fff;

    &:hover {
        background-color: rgb(253, 201, 108);
    }

    &:active {
        background-color: rgb(222, 153, 20);
        border-color: #fff;
    }
}

.regive-normal {
    color: rgb(222, 153, 20);
    background-color: #fff;
    border: 1px solid rgb(222, 153, 20);
}

.xin {
    font-size: 30px;
    vertical-align: middle;
    color: #999;
    cursor: pointer;
}

.ping {
    color: #ff8190;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}

.show-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.el-table {
    overflow: auto;
}

.el-table__header-wrapper,
.el-table__body-wrapper,
.el-table__footer-wrapper {
    overflow: visible;
}

.el-table__body-wrapper {
    overflow-x: visible !important;
}

/* 这个是为了解决前面样式覆盖之后伪类带出来的竖线 */
.el-table::after {
    position: relative;
}
</style>
