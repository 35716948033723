import request from './request.js';
import public_request from './public_request';
//获取团队客户列表
export function clients(data) {
    return request({
        url: '/client-management/team/clients',
        method: 'get',
        params: public_request(data),
    });
}
//重分配团队客户
export function exchange({ clientIds, staffId }) {
    return request({
        url: '/client-management/team/clients-owner',
        method: 'PUT',
        data: public_request({
            clientIds,
            staffId,
        }),
    });
}
