import { render, staticRenderFns } from "./teamclient.vue?vue&type=template&id=982074b0&scoped=true&"
import script from "./teamclient.vue?vue&type=script&lang=js&"
export * from "./teamclient.vue?vue&type=script&lang=js&"
import style0 from "./teamclient.vue?vue&type=style&index=0&id=982074b0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "982074b0",
  null
  
)

export default component.exports